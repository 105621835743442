import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import CtaDeck from '../components/common/cta-deck';
import Testimonials from '../components/common/testimonials';
import {
  NEW_YORK_DOWNTOWN_PHONE,
  NEW_YORK_DOWNTOWN_PHONE_DISPLAY,
} from '../data/site-data';
import { NEW_YORK_DOWNTOWN_TESTIMONIALS } from '../data/new-york-downtown/testimonials';
import OfficeMeta from '../components/contact/office-meta';
import AbTestingDeck from '../components/ab-testing/ab-testing-deck';
import PilarNavigation from '../components/pilar/pilar-navigation';

const headMeta = {
  title: 'New York Immigration Lawyer Near Me | NYC Downtown | Pandev Law',
  description:
    'Searching for a trusted and experienced Immigration Lawyer near you in Downtown, New York? We help with visas, green cards, naturalization and work permits. Talk to us now!',
  keywords:
    'New York immigration lawyer,immigration lawyer near me,immigration lawyer NYC',
};

const schema = `{
  "@context": "http://schema.org",
  "@type": "LegalService",
  "name": "New York Immigration Lawyer Near Me | NYC Downtown | Pandev Law",
  "description": "Searching for a trusted and experienced Immigration Lawyer near you in Downtown, New York? We help with visas, green cards, naturalization and work permits. Talk to us now!",
  "url": "https://www.pandevlaw.com/new-york-immigration-lawyer-downtown",
  "image": "https://pandevlaw.com/img/new-york-office-downtown.jpg",
  "priceRange": "$$",
  "telephone": "${NEW_YORK_DOWNTOWN_PHONE_DISPLAY}",
  "email": "adrian@pandevlaw.com",
  "hasMap": "https://www.google.com/maps/place/One+World+Trade+Center/@40.7129987,-74.0153496,17z/data=!3m1!4b1!4m5!3m4!1s0x89c25a197c06b7cb:0x40a06c78f79e5de6!8m2!3d40.7127431!4d-74.0133795",
  "address": {
    "@type": "PostalAddress",
    "addressLocality": "New York",
    "addressRegion": "NY",
    "postalCode": "10007",
    "streetAddress": "One World Trade Center, Suite 8500"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": "40.712983",
    "longitude": "-74.013360"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.8",
    "bestRating": "5",
    "worstRating": "0",
    "ratingCount": "246"
  },
  "sameAs": [
    "https://en-gb.facebook.com/pandevlaw/"
  ],
  "openingHours": "Mo,Tu,We,Th,Fr, 8:30am-5:30pm"
}`;

function NYDowntownOffice({ location }) {
  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema}>
      <StaticImage
        className="img-fluid d-lg-none"
        src="../assets/img/ny-office-downtown.jpg"
        quality={40}
        alt="New York immigration lawyer near me"
      />
      <div className="section pt-5 pb-5 pt-md-10 pb-md-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <StaticImage
                className="img-fluid d-none d-lg-inline-block"
                src="../assets/img/new-york-office-downtown.jpg"
                alt="New York immigration lawyer near me"
                quality={40}
                placeholder="none"
                loading="eager"
              />
            </div>
            <div className="col-lg-7 pl-lg-6">
              <h1 className="text-hero mb-5 mb-md-8">
                New York Immigration Lawyer - Downtown
              </h1>
              <div className="d-flex mb-5">
                <div>
                  <p className="text-lead text-grey mb-3">Call us at:</p>
                  <a
                    href={`tel:${NEW_YORK_DOWNTOWN_PHONE}`}
                    className="phone-new-york-downtown text-phone cta-green">
                    <i className="fas fa-phone fa-flip-horizontal icon-phone" />
                    {NEW_YORK_DOWNTOWN_PHONE_DISPLAY}
                  </a>
                  <p className="text-lead text-grey mt-3">For a consultation</p>
                </div>
              </div>
              <div className="mb-5">
                <p className="text-mega font-size-24 mb-4">As featured in:</p>
                <div className="d-flex align-items-center">
                  <a
                    href="https://profiles.superlawyers.com/new-york/new-york/lawyer/adrian-pandev/04411de3-60c5-4ace-aa12-e98485d9be2b.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3 mr-lg-5">
                    <StaticImage
                      height={50}
                      src="../assets/img/superlaweyrs.png"
                      alt="Super Lawyers - Pandev Law - Immigration Lawyer"
                      placeholder="none"
                      loading="eager"
                    />
                  </a>
                  <a
                    href="https://www.globallegalinsights.com/firms/pandev-law-llc"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3 mr-lg-5">
                    <StaticImage
                      height={50}
                      quality={100}
                      src="../assets/img/globallegalinsights.png"
                      alt="Global Legal Insights - Pandev Law - Immigration Lawyer"
                      placeholder="none"
                      loading="eager"
                    />
                  </a>
                  <a
                    href="https://www.managehrmagazine.com/pandev-law-llc"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3 mr-lg-5">
                    <StaticImage
                      height={150}
                      quality={100}
                      src="../assets/img/pandevlaw-award-logo.png"
                      alt="Manage HR Magazine"
                      placeholder="none"
                      loading="eager"
                    />
                  </a>
                  <a
                    href="https://www.americastop50lawyers.com/product-page/adrian-m-pandev-america-s-top-50-lawyers-immigration-law-new-york"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3 mr-lg-5">
                    <StaticImage
                      height={120}
                      quality={100}
                      src="../assets/img/america-top-50-lawyers.png"
                      alt="America Top 50 Lawyers"
                      placeholder="none"
                      loading="eager"
                    />
                  </a>
                </div>
              </div>
              <p className="text-body">
                If you are searching for “immigration lawyer near me”, you are at the
                right place. Pandev Law's downtown New York immigration law office is
                located at One World Trade Center in Manhattan’s Financial District. For
                the convenience of our clients who work downtown, our New York immigration
                lawyer is located in close proximity to many of New York’s major financial
                institutions, including the New York Stock Exchange and the Federal
                Reserve Bank of New York. Our NYC immigration lawyer office is easily
                accessible by subway, through the Fulton Center Station, and PATH trains,
                through the World Trade Center station.
                <br />
                <br />
                <strong>&ldquo;The City that Never Sleeps&rdquo;</strong>
                is the global capital of international commerce, finance, media, art,
                fashion, technology and entertainment. As the largest and most
                multicultural city in the United States, New York has forever been home to
                countless foreign nationals and businesses. We are proud of our reputation
                as a go-to New York immigration law firm serving the City’s large
                immigrant and international business communities.
                <br />
                <br />
                Our New Yok immigration lawyer represents individuals and organizations in
                various industries, including technology, e-commerce, engineering,
                academia, fashion, healthcare, consulting, biotechnology, food and
                beverage, research, and construction industries. Your search for
                “immigration lawyer near me” has brought you to the right place. If you
                are seeking effective business or immigration representation, contact
                Pandev Law’s NYC immigration law office and inquire about our immigration
                lawyer New York services.
              </p>
            </div>
          </div>
        </div>
      </div>

      <AbTestingDeck
        position="top"
        title="New York Downtown Client Reviews"
        testimonials={NEW_YORK_DOWNTOWN_TESTIMONIALS}
      />

      <div className="section pt-5 pb-5 pt-md-10 pb-md-10">
        <div className="container">
          <div className="row mt-5 mt-lg-13">
            <div className="col-lg-6 mb-5 mb-lg-0 order-lg-12">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/new-york-office-downtown-outside.jpg"
                alt="Immigration Lawyer New York"
                quality={20}
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <p className="text-body">
                <strong>Immigration Lawyer New York</strong>
                <br />
                Immigration Lawyer New York - Our immigration lawyer NYC services focuses
                on <a href="/investor-visa-usa-immigration">USA investor visa</a>,{' '}
                <a href="/family-based-green-card-immigration">
                  family-based immigration
                </a>
                , and{' '}
                <a href="/employment-based-visa-immigration">
                  employment-based immigration
                </a>
                , particularly <a href="/eb-5-visa">EB-5 Investors</a> and{' '}
                <a href="/e-2-visa">E-2 Treaty Investors</a>, H-1B Specialty Workers, and{' '}
                <a href="/l-1-visa">L-1 Intracompany Transferees</a>. If you are looking
                for an “immigration attorney near me,” we have the experience to help you
                in your immigration case. We also regularly advise startups and
                established organizations regarding visa sponsorship for foreign
                employees. Our immigration attorney NYC services include immigration
                compliance, such as Form I-9 and E-Verify compliance. In addition, we have
                extensive experience with the employment-based permanent residence
                process, including PERM Labor Certifications, EB-2 National Interest
                Waivers, and EB-1 petitions for Multinational Managers and Individuals of
                Extraordinary Ability, including academics, researchers, scientists,
                artists, and athletes. If you are searching for a “business immigration
                lawyer near me” or an “employment-based immigration lawyer near me,” we
                have the experience and know-how to help you in your immigration case.
                Contact us to inquire about our immigration lawyer NYC services.
                <br />
                <br />
                <strong>Immigration Lawyer NYC</strong>
                <br />
                Are you searching for a “family-based immigration lawyer near me” to help
                you sponsor a visa or green card for a family member? We have extensive
                experience uniting families through marriage, fiance, and other
                family-based immigration petitions. Contact us to inquire about our
                immigration lawyer NYC services.
              </p>
            </div>
          </div>
          <div className="row mt-5 mt-lg-13">
            <div className="col-lg-6 mb-5 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/new-york-office-downtown-inside.jpg"
                alt="Teacher, Professor, and Researcher Immigration Attorney NYC"
              />
            </div>
            <div className="col-lg-6">
              <p className="text-body">
                <strong>
                  Teacher, Professor, and Researcher Immigration Attorney NYC
                </strong>
                <br />
                Are you a teacher, professor, researcher or an educational institution
                seeking an “immigration lawyer near me”? We specialize in representing
                educational institutions, including primary schools and colleges, in
                immigration related matters. Contact us to inquire about our immigration
                attorney NYC services.
                <br />
                <br />
                <strong>International Business Lawyer New York</strong>- Our business law
                firm focuses on advising entrepreneurs, small, and medium sized businesses
                in formation and governance, mergers and acquisitions, commercial
                transactions, and financing, including venture capital. We have extensive
                experience representing foreign companies entering the US market and
                regularly advise on cross-border transactions. Our NYC international
                business lawyer advises on import and export compliance and helps obtain
                relief for alleged customs law violations. We represent foreign blockchain
                startups and cryptocurrency investors who wish to move their business to
                the US.
                <br />
                <br />
                Whether you are searching for “immigration lawyer near me”, “international
                business lawyer near me,” or “immigration attorney near me,” you have come
                to the right place. Contact Pandev Law’s downtown New York office for all
                your business or immigration law needs.
              </p>
            </div>
          </div>
        </div>
      </div>

      <OfficeMeta
        googleReviewsCount={66}
        googleRating={5.0}
        officeTitle={
          <>
            New York
            <br />
            Immigration Office - Downtown
          </>
        }
        addressLine1="One World Trade Center, Suite 8500"
        addressLine2="New York, NY 10007"
        telephone={NEW_YORK_DOWNTOWN_PHONE}
        displayTelephone={NEW_YORK_DOWNTOWN_PHONE_DISPLAY}
        mapCenter={{
          lat: 40.712983,
          lng: -74.01336,
        }}
        googleBusinessLink="https://www.google.bg/maps/place/Pandev+Law/@40.7130082,-74.0153576,17z/data=!3m1!4b1!4m7!3m6!1s0x89c25baac218927f:0xdae73cf56396b780!8m2!3d40.7130082!4d-74.0131689!9m1!1b1"
      />
      <AbTestingDeck position="bottom" />
      <PilarNavigation />
      <CtaDeck />
    </Layout>
  );
}

export default NYDowntownOffice;
