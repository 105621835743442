export const NEW_YORK_DOWNTOWN_TESTIMONIALS = [
	{
		text: `Adrian successfully obtained our H-1B and H-4 extensions in less than 30 days. He worked diligently, efficiently and was always available to answer any questions. Adrian is a great lawyer, he is highly recommended.`,
		name: 'Simone Hinds',
		stars: 5,
	},
	{
		text: `Adrian provided me and my husband the best professional and compassionate service of any attorney we have ever had. He is LGBT friendly and would be the only immigration attorney I recommend to combat today’s difficult policies. We had a quick green card approval through his hard work and dedication. This man actually cares about his clients. Thank you Adrian!!!`,
		name: 'Daniel Levine',
		stars: 5,
	},
	{
		text: `We thoroughly enjoyed working with Adrian and felt extremely comfortable with our immigration case being in his hands. We always felt well informed throughout the visa process and everything went so smoothly! He conducts himself very professionaly and you can tell that him and his team really care for their clients. Nothing but a positive experience with this firm, we can’t thank them enough.`,
		name: 'Katheryn Tremblay',
		stars: 5,
	},
	{
		text: `I went to Adrian for an I-751 RFE and 6 months later I am a U.S. citizen! When I received the RFE in March, I was traveling for work. The case got complex because the request came when both my husband and I were super busy at the time. We were frustrated with the unnecessarily lengthy process and couldn't contact my current lawyer, the one who helped me from the beginning. While all consultations pretty much gave us the same answer/direction, we felt the most safety when talking to Adrian.. His assertive attitude towards my case after listening to what happened made we feel confident to move forward. Adrian created a custom strategy tailoring to our not so traditional case.The Saturday before my Monday interview, he called to inform us of how interviews have happened across the US. He truly cares for his clients as lawyers are created to do, not just another business transaction. Good luck to you and thank you Adrian!`,
		name: 'Tino Nguyen Dinh',
		stars: 5,
	},
	{
		text: `I am a Canadian artist who needed help with US Immigration. Adrian advised me on the right visa and guided me through the entire process. Highly recommended to any foreign artist looking to work in the US`,
		name: 'Pawel O',
		stars: 5,
	},
	{
		text: `What an efficient consultation.  I received answers to a myriad of specific questions during our 30 min conversation. Mr. Panvev spotted and helped rectify all the flaws in my UCSIC application,  including updates and recent changes to the immigration legislation. Each case is so specific and an experienced immigration lawyer is key to make sure your Green Card process is seamless. Pandev Law is highly recommended to anyone.`,
		name: 'Lisa Rakhimova',
		stars: 5,
	},
	{
		text: `Adrian was very helpful with my E-2 visa. His focus on both the business and immigration side of the process was superb. Highly recommended for those seeking to start a business in the US.`,
		name: 'Irina Liza',
		stars: 5,
	},
	{
		text: `Adrian is a great lawyer! I’m an attorney, from the Bahamas, who needed immigration help and he was absolutely amazing! Truly cares about your case and works harder than anyone. I highly recommend him.`,
		name: 'Jonathan Stuart',
		stars: 5,
	},
	{
		text: `Super helpful and knowledgeable immigration.Was very thorough explaining everything and providing a sound strategy for my National Interest Waiver case.`,
		name: 'Angela Emly',
		stars: 5,
	},
	{
		text: `I consulted the firm for a visa status change. The office has been very prompt with their responses and professional from the beginning. Thinking about the process gave me a lot of anxiety. Consulting Adrian definitely left me feeling more comfortable.  He gave me realistic options and great advice for my situation. I would definitely seek their help for any future immigration problems.`,
		name: 'Shruthi Janardhan',
		stars: 5,
	},
];
